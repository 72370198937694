import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import { Helmet } from "react-helmet";
import movingbed from "../images/moving-bed.jpg";

function LocalMoving() {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="description"
          content="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best local mover in Kansas City Missouri. KC local moving company."
        />
        <title>Local Moving | Point Blank Moving</title>
        <link rel="canonical" href="http://pointblankmoving/LocalMoving" />
      </Helmet>

      <NavBar
        title="Local Moving | Point Blank Moving"
        description="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best local mover in Kansas City Missouri. KC local moving company."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${movingbed})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Local Moving <br /> Kansas City, MO
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h2
              style={{
                fontSize: "30px",
                marginTop: 20,
                marginBottom: 10,
                padding: 10,
                textAlign: "center",
              }}
            >
              Local Moving Company in Kansas City
            </h2>
            <div>
              Local moving is a cumbersome experience for almost anyone & there
              are more than enough horror stories out there to validate. We
              strive to keep things simple. We give you your choice of a flat
              rate or hourly and never include your load with someone else's. We
              give you the most precise and immediate arrival time possible
              because your load and team will be dedicated to your job only.
            </div>
          </div>
          <div className="content--loc--generic">
            <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Local Moving Services - Kansas City, MO
            </h3>
            <div>
              Not only can Point Blank Moving move you anywhere in Kansas City,
              we also have the resources and experience necessary to move your
              household no matter the size. We provide basic relocation services
              as well as full-service residential moving, Point Blank has Kansas
              City local moving services that meet your needs.
              <br />
              <br />
              In getting started, one of our trained, courteous professionals
              will come to your home for a free consultation, during which you
              will receive a written estimate of moving costs. When you choose
              Point Blank Moving as your Kansas City long distance movers,
              expect the following benefits and amenities:
              <ul style={{ marginTop: 10, marginLeft: 30 }}>
                <li>Licensed and Bonded</li>
                <li>Professional, Uniformed Staff</li>
                <li>Full-Service Packing</li>
                <li>Quality Packing Materials</li>
                <li>Crating for High Value Items</li>
                <li>Floor & Furniture Protection</li>
                <li>Unpacking & Debris Removal</li>
              </ul>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
                You Need Experienced Local Movers – Hire Us
              </h3>
              <div>
                At Point Blank Moving, we are a veteran led team with 20+ years
                of logistics experience covering all areas of Kansas City -
                Missouri and/or Kansas.
              </div>
            </div>
            <div className="content--loc--generic">
              <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
                You Need Movers Who Know How to Be Careful – Hire Us
              </h3>
              <div>
                When hiring a local movers for your household goods, you need
                movers who are going to treat your property as if they were
                their own. We get you in contact with one of our local moving
                planning team members to your home to explain the entire process
                to you, go over what items you plan to move and provide you with
                a written estimate for your move. Your dedicated team member
                will stay with you step by step throughout the entire move
                keeping you up-to-date of where your move is in the process.
                <br />
                <br />
                If you are getting ready to relocate,{" "}
                <strong>
                  contact Point Blank Moving in Kansas City, your trusted local
                  movers at (816) 506-8304
                </strong>{" "}
                and talk to one of our{" "}
                <strong>
                  expert team members today or request a free moving quote.
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LocalMoving;
